import {
 FC, useEffect, useLayoutEffect, useMemo
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { AppColorsEnum } from "../../../App.interface";
import {
  setAppColor,
  showFooter,
  showHeader,
} from "../../../store/actions/LayoutActions";
import { getPageFromUrl } from "../../../utils";
import { SelectionFolders } from "../../../utils/constants/proposal";
import PageTitle from "../../presentational/PageTitle/PageTitle";
import "./Selection.scss";
import IdeaTransferHistoryModal from "./components/SelectionIdeaTransferHistoryModal/SelectionIdeaTransferHistoryModal";
import useUpdateEffect from "../../../utils/hooks/useUpdateEffect";
import UserService from "../../../services/userService";
import { stageSelector } from "../../../store/selectors/stage";
import { getAllStages } from "../../../store/actions/StageActions";
import {
  selectionGetCounts,
  selectionGetExperts,
  selectionGetGenerationStage,
  selectionGetIdeas,
  selectionGetRejectReasons,
  selectionGetTags,
  SELECTION_SET_IDEAS_CURRENT_PAGE,
  SELECTION_SET_IS_TAG_EDITING,
  SELECTION_SET_SEARCH_FILTER_VALUE,
  selectionSetDefaultFilter,
  selectionSetFilter,
} from "../../../store/actions/SelectionActions";
import {
  selectionFilterSelector,
  selectionFilterTypeSearchSelector,
  selectionIdeasForCombiningSelector,
  selectionIdeaShowTransferHistorySelector,
  selectionIsForwardedShowAllSelector,
  selectionIsLoadingSelector,
  selectionPageSelector,
} from "../../../store/selectors/selection";
import { SelectionLoader } from "./components/SelectionLoader/SelectionLoader";
import { SelectionRemained } from "./components/SelectionRemained/SelectionRemained";
import { SelectionMainContent } from "./components/SelectionMainContent/SelectionMainContent";
import { SelectionPagination } from "./components/SelectionPagination/SelectionPagination";
import { SelectionReservationModal } from "./components/SelectionReservationModal/SelectionReservationModal";
import { SelectionFinalizationButtons } from "./components/SelectionFinalizationButtons/SelectionFinalizationButtons";
import { SelectionUnreviewed } from "./components/SelectionUnreviewed/SelectionUnreviewed";
import { SelectionIdeasCombining } from "./components/SelectionIdeasCombining/SelectionIdeasCombining";
import { SelectionParamsContext } from "./helpers/params";
import { TSelectionParams } from "./types/types";

const Selection: FC = () => {
  const history = useHistory();
  const { folder, stageId } = useParams<TSelectionParams>();

  const paramsContextValue = useMemo(
    () => ({ folder, stageId }),
    [folder, stageId]
  );

  const dispatch = useDispatch();

  const stage = useSelector(stageSelector);
  const page = useSelector(selectionPageSelector);
  const filter = useSelector(selectionFilterSelector);
  const filterTypeSearch = useSelector(selectionFilterTypeSearchSelector);
  const ideasForCombining = useSelector(selectionIdeasForCombiningSelector);
  const ideaShowTransferHistory = useSelector(
    selectionIdeaShowTransferHistorySelector
  );
  const isForwardedShowAll = useSelector(selectionIsForwardedShowAllSelector);

  const isLoading = useSelector(selectionIsLoadingSelector);

  useLayoutEffect(() => {
    dispatch({
      type: SELECTION_SET_IDEAS_CURRENT_PAGE,
      payload: getPageFromUrl(history) || 0,
    });
    dispatch(selectionSetDefaultFilter());

    dispatch(setAppColor(AppColorsEnum.WHITE));
    dispatch(showHeader());
    dispatch(showFooter());
  }, []);

  useEffect(() => {
    if (
      ["authorId", "expertId"].includes(filterTypeSearch.value) &&
      filter[filterTypeSearch.value]
    ) {
      UserService.getPerson(filter[filterTypeSearch.value]).then(({ data }) => dispatch({
          type: SELECTION_SET_SEARCH_FILTER_VALUE,
          payload: `${data.firstName} ${data.lastName}`,
        }));
    } else {
      dispatch({
        type: SELECTION_SET_SEARCH_FILTER_VALUE,
        payload: filter[filterTypeSearch.value] || "",
      });
    }

    dispatch(selectionGetExperts());
  }, []);

  useEffect(() => {
    dispatch(getAllStages(stageId));
    dispatch(selectionGetGenerationStage(stageId));
    dispatch(selectionGetRejectReasons(stageId));
    dispatch(selectionGetCounts(stageId));
    dispatch(selectionGetTags(stageId));
  }, [stageId]);

  useUpdateEffect(() => {
    dispatch(selectionSetFilter({}));
  }, [stageId, folder]);

  useEffect(() => {
    const tid = setTimeout(() => {
      dispatch(
        selectionGetIdeas({
          stageId,
          folder,
          isForwardedShowAll,
        })
      );
    }, 100);
    return () => clearTimeout(tid);
  }, [folder, stageId, filter, page]);

  useEffect(() => {
    dispatch(
      selectionGetIdeas({
        stageId,
        folder,
        isForwardedShowAll,
      })
    );
  }, [isForwardedShowAll]);

  useEffect(() => {
    dispatch({ type: SELECTION_SET_IS_TAG_EDITING, payload: false });
  }, [filter.tagId, folder]);

  useEffect(() => {
    const params = new URLSearchParams(filter as any);
    const search = params ? `?${params}` : "";

    if (history.location.search !== search) {
      history.push(`${history.location.pathname}${search}`);
    }
  }, [filter]);

  return (
    <SelectionParamsContext.Provider value={paramsContextValue}>
      <section className="selection-page">
        {isLoading ? (
          <SelectionLoader />
        ) : (
          <>
            <PageTitle text={`Отбирай: ${stage.title}`} />
            <div className="selection-page__indicators">
              <SelectionRemained />
              <SelectionUnreviewed />
            </div>
            <SelectionMainContent />
          </>
        )}
        {!!ideaShowTransferHistory && <IdeaTransferHistoryModal />}
        {!!ideasForCombining && <SelectionIdeasCombining />}
        <SelectionReservationModal />
        <SelectionPagination />

        {folder === SelectionFolders.ACCEPTED && (
          <SelectionFinalizationButtons />
        )}
      </section>
    </SelectionParamsContext.Provider>
  );
};

export default Selection;
