import React, {
  Fragment,
  Suspense,
  useEffect,
  useRef,
  useState,
  useMemo,
  useContext,
} from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "@crowd/ui-kit";

import { useToasts } from "react-toast-notifications";
import HeaderContainer from "./components/containers/Header/Header";
import { RootState } from "./types/State.interface";
import { getExperts, getUser, logout } from "./store/actions/UserActions";
import {
  getCurrentProjects,
  getProjectStatistics,
  getProjectStatus,
} from "./store/actions/ProjectActions";
import { getAccessibleIds, getAllStages } from "./store/actions/StageActions";
import Footer from "./components/containers/Footer/Footer";
import AuthModal from "./components/containers/Modals/AuthModal/AuthModal";
import InfoModal from "./components/containers/Modals/InfoModal/InfoModal";
import MaintenanceModal from "./components/containers/Modals/MaintenanceModal/MaintenanceModal";
import { getCountAllUnread } from "./store/actions/NotificationsActions";
import {
  hideInfoModal,
  hideUserModal,
  hideMaintenanceModal,
} from "./store/actions/LayoutActions";
import "./App.scss";
import { getAnswersComments } from "./store/actions/CommentActions";
import { getDiscussionByType } from "./store/actions/DiscussionActions";
import GuardService from "./routing/GuardService";
import EmailVerifiedGuard from "./routing/guards/EmailVerifiedGuard";
import indexedRoutes from "./routing/IndexedRoutes";
import legacyRoutes from "./routing/LegacyRoutes";
import BlockedPage from "./components/pages/Blocked/BlockedPage";
import { getEnvVariables } from "./store/actions/CommonActions";
import BlockedUserGuard from "./routing/guards/BlockedUserGuard";
import baseRoutes from "./routing/BaseRoutes";
import MainPage from "./components/pages/Main/Main";
// import Chat from "./components/presentational/Chat/Chat"; // временно скрываем чат
import UserModal from "./components/containers/Modals/UserModal/UserModal";
import { ToastState } from "./types/Toast.interface";
import MetaDecorator from "./components/presentational/MetaDecorator/MetaDecorator";
import ErrorBoundary from "./components/containers/ErrorBoundary/ErrorBoundary";
import { debounce } from "./utils";
import { getScreenWidth, getScroll } from "./store/actions/GlobalEventsActions";
import ScrollTopButton from "./components/presentational/Controls/ScrollTopButton/ScrollTopButton";
import LicensePage from "./components/pages/License/LicensePage";
import PointsRulesPage from "./components/pages/PointsRules/PointsRulesPage";
import { getBadWords } from "./store/actions/BadWords";
import PrivatePage from "./components/pages/Private/PrivatePage";
import NotFoundPage from "./components/pages/NotFound/NotFoundPage";
import TeamLoginPage from "./components/pages/TeamLogin/TeamLogin";
import SignInFail from "./components/pages/SignInFail/SignInFail";
import { ProjectStatus } from "./types/Projects.interface";
import { loadMetrics } from "./services/analytics";
import ChangePasswordPage from "./components/pages/ChangePassword/ChangePasswordPage";
import EventsTrackParentIdContext from "./components/containers/EventsTrack/EventsTrackParentIdContext";
import ProjectQuestionnaireForm from "./components/containers/Forms/ProjectQuestionnaireForm/ProjectQuestionnaireForm";
import AfterRegistrationForm from "./components/containers/Forms/AfterRegistrationForm/AfterRegistrationForm";
import { AuthActionType, ComponentType } from "./services/sudirService";
import { AppContext } from "./Root";
import AuthChildForbidden from "./components/containers/Modals/AuthChildForbidden/AuthChildForbidden";
import {
  selectIsNeedAfterRegistrationForm,
  selectIsUserChild,
} from "./store/selectors/profile";

/*
 * Является id проекта, если проекта нет.
 * Например: старый отправили в архив, а новый не загрузили из админки.
 * Значение менять/удалять только по согласованию и одновременно с бэком!
 */
const EMPTY_PROJECT_ID = "dummy_project_id";

const App = () => {
  const appContext = useContext(AppContext);
  const status: ProjectStatus = useSelector(
    (state: RootState) => state.project.status,
  );
  const user = useSelector((state: RootState) => state.user.userDetails);
  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);
  const appRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToasts();
  const layout = useSelector((state: RootState) => state.layout);
  const toast = useSelector((state: RootState) => state.toast);
  const project = useSelector((state: RootState) => state.project.current);
  const promoUrl = useSelector(
    (state: RootState) => state.common.environment?.promoUrl,
  );
  const [isAccessAllow, setAccessAllow] = useState<boolean>(false);
  const [afterRegisterFormOpen, setAfterRegisterFormOpen] = useState<boolean>(false);
  const [projectQuestionnaireFormOpen, setProjectQuestionnaireFormOpen] = useState<boolean>(false);
  const isNeedAfterRegistrationForm = useSelector(
    selectIsNeedAfterRegistrationForm,
  );
  const isUserChild = useSelector(selectIsUserChild);

  useEffect(() => {
    window.scrollTo({ top: 0 });

    // обновляем счетчик у аватара при навигации по сайту
    // возможно стоит отрефакторить в единый эндпоинт на бэке, и по запросу заказчика переделать фронт в фоновый рефреш интервалами
    if (loggedIn) {
      dispatch(getCountAllUnread());
    }
  }, [history.location.pathname]);

  useEffect(() => {
    loadMetrics();
    dispatch(getProjectStatus());
    loadUserComponents();
    dispatch(getEnvVariables());
    dispatch(getCurrentProjects());
    dispatch(getAllStages());
    dispatch(getProjectStatistics());
    dispatch(getExperts());
    dispatch(getBadWords());
    dispatch(getAnswersComments());
    dispatch(getDiscussionByType({ type: "PROJECT_DISCUSSIONS" }));

    const setWidth = () => dispatch(getScreenWidth());
    window.addEventListener(
      "resize",
      debounce((e) => setWidth(), 500),
    );
    setWidth();

    const setScroll = () => dispatch(getScroll());
    window.addEventListener(
      "scroll",
      debounce((e) => setScroll(), 10),
    );
    setScroll();
  }, []);

  useEffect(() => {
    if (toast.text && toast.appearance) {
      showToast(toast);
    }
  }, [toast]);

  // ACTIONS AFTER AUTH
  useEffect(() => {
    if (user) {
      if (!isNeedAfterRegistrationForm) {
        const actions = appContext.sudirService.getActions(
          ComponentType.MainPage,
        );
        if (actions.length) {
          const redirectAction = actions.find(
            (act) => act.type === AuthActionType.Redirect,
          );
          if (redirectAction) {
            history.push(redirectAction.args.redirectUrl);
          }
        }
      }
    }
  }, [user, isNeedAfterRegistrationForm]);

  useEffect(() => {
    if (
      user?.needToCompleteQuestionnaire
      && user?.emailVerified
      && !isUserChild
      && !isNeedAfterRegistrationForm
    ) {
      setProjectQuestionnaireFormOpen(true);
    }
  }, [
    user?.needToCompleteQuestionnaire,
    isNeedAfterRegistrationForm,
    user?.emailVerified,
    isUserChild,
  ]);

  useEffect(() => {
    if (isNeedAfterRegistrationForm) {
      setAfterRegisterFormOpen(true);
    }
  }, [isNeedAfterRegistrationForm]);

  // ACCSES
  const availableRoutes = ["/changepassword"];
  useEffect(() => {
    if (user?.blocked) {
      setAccessAllow(true);
      history.push("/blocked");
    } else if (status && project && user && promoUrl) {
      manageAccess();
    } else if (availableRoutes.includes(window.location.pathname)) {
      setAccessAllow(true);
    }
  }, [status, project, user, promoUrl]);

  const manageAccess = (): void => {
    if (status?.private) {
      const isOngoing = status.started && !status.finished;
      if (isOngoing) {
        return setAccessAllow(true);
      }
      if (user.teamMember && user.accountType === "NATIVE") {
        return setAccessAllow(true);
      }
      window.location.href = promoUrl;
      return;
    }

    // Если проект отсутствует (не отправлен)
    // Без возможности зайти на /teamLogin
    if (project.id === EMPTY_PROJECT_ID) {
      // юзеры из админки -> редирект на страницу /notStarted
      if (user.teamMember && user.accountType === "NATIVE") {
        history.push("/notStarted");
        setAccessAllow(true);
        return;
      }

      // все, кроме юзеров из админки -> редирект на промо
      window.location.href = promoUrl;
      return;
    }

    // Если проект есть то страница /teamLogin доступна всем
    if (
      history.location.pathname === "/teamLogin"
      || history.location.pathname === "/blocked"
    ) {
      setAccessAllow(true);
      return;
    }

    // Управление доступом к платформе в зависимости от роли и типа юзера
    redirectIfProjectInactive();
  };

  const redirectIfProjectInactive = () => {
    if (isPrjInactive()) {
      // Разрешен вход в платформу с неактивным проектом для ЧКП с teamLogin
      if (user.teamMember && user.accountType === "NATIVE") {
        setAccessAllow(true);
        return;
      }

      window.location.href = promoUrl;
      return;
    }

    setAccessAllow(true);
  };

  const isPrjInactive = () => (
    project.closed
      || !project.start
      || !project.finish
      || new Date(project.start).getTime() > new Date().getTime()
      || new Date(project.finish).getTime() < new Date().getTime()
  );

  const loadUserComponents = async () => {
    await dispatch(getUser(dispatchAccessibleIds));
    dispatch(getCountAllUnread());
  };
  const dispatchAccessibleIds = () => dispatch(getAccessibleIds());

  const getGuardedRoutes = useMemo(() => {
    const routes = (
      <>
        { [...legacyRoutes] }
        { [...indexedRoutes] }
        { [...baseRoutes] }
      </>
    );

    const guardService = new GuardService([
      BlockedUserGuard,
      EmailVerifiedGuard,
    ]);

    return guardService.getGuarded(routes);
  }, [user]);

  const showToast = (toastConf: ToastState) => {
    addToast(toastConf.text, {
      appearance: toastConf.appearance,
      autoDismiss: toastConf.autoDismiss,
    });
  };

  const renderAfterRegisterForm = () => {
    if (!afterRegisterFormOpen) return;

    return (
      <AfterRegistrationForm
        isOpen={ afterRegisterFormOpen }
        onClose={ async () => {
          setAfterRegisterFormOpen(false);

          const actions = appContext.sudirService.getActions(
            ComponentType.MainPage,
          );
          const redirectAction = actions.find(
            (act) => act.type === AuthActionType.Redirect,
          );
          let redirectUrl;
          if (redirectAction) {
            redirectUrl = redirectAction.args.redirectUrl;
          } else {
            const actions = appContext.sudirService.getActions(
              ComponentType.AFTER_REGISTRATION_FORM,
            );
            const redirectToLastVisitedPage = actions.find(
              (act) => act.type === AuthActionType.VISIT_LAST_PAGE,
            );
            if (redirectToLastVisitedPage) {
              redirectUrl = redirectToLastVisitedPage.args.lastVisitedPage;
            }
          }
          await dispatch(getAllStages());
          dispatch(
            getUser(() => {
              !!redirectUrl && history.push(redirectUrl);
            }),
          );
        } }
        closeWithLogout={ () => {
          // таймаут для закрытия по нажатию на esc и редиректа на логаут
          setTimeout(() => {
            setAfterRegisterFormOpen(false);
            dispatch(logout());
          });
        } }
      />
    );
  };

  const renderProjectQuestionnaireForm = () => {
    if (!projectQuestionnaireFormOpen) return;

    return (
      <ProjectQuestionnaireForm
        isOpen={ projectQuestionnaireFormOpen }
        onClose={ () => {
          setProjectQuestionnaireFormOpen(false);
          dispatch(getUser());
        } }
        closeWithLogout={ () => {
          // таймаут для закрытия по нажатию на esc и редиректа на логаут
          setTimeout(() => {
            setProjectQuestionnaireFormOpen(false);
            dispatch(logout());
          });
        } }
      />
    );
  };

  const renderMeta = () => (
    <MetaDecorator
      title="Платформа Правительства Москвы «Город идей»"
      description="Платформа «Город идей» позволяет москвичам предлагать идеи по решению отдельных вопросов жизнедеятельности столицы и голосовать за предложения, отобранные квалифицированными экспертами. Идеи, набравшие наибольшее количество голосов, реализуются."
    />
  );

  const routes = useMemo(() => {
    if (!user) return;

    if (user?.blocked) {
      return (
        <Switch>
          <Route
            exact
            path="/blocked"
            component={ BlockedPage }
            key="blocked"
          />
          <Route
            exact
            path="/teamLogin"
            component={ TeamLoginPage }
            key="TeamLoginPage"
          />
        </Switch>
      );
    }

    if (user?.passwordExpired) {
      return (
        <Switch>
          <Route
            exact
            path="/changepassword"
            component={ ChangePasswordPage }
            key="ChangePassword"
          />
          ,
        </Switch>
      );
    }

    const baseRoutes = (
      <Switch>
        <Route exact path="/" component={ MainPage } key="MainPage" />
        <Route exact path="/blocked" component={ BlockedPage } key="blocked" />
        <Route exact path="/license" component={ LicensePage } key="license" />
        <Route
          exact
          path="/pointsRules"
          component={ PointsRulesPage }
          key="points"
        />
        <Route
          exact
          path="/changepassword"
          component={ ChangePasswordPage }
          key="ChangePassword"
        />
        ,{ getGuardedRoutes }
      </Switch>
    );

    if (status?.private) {
      if (user.loggedIn) {
        return baseRoutes;
      }
      return (
        <Switch>
          <Route exact path="/" component={ PrivatePage } key="PrivatePage" />
          <Route
            exact
            path="/teamLogin"
            component={ TeamLoginPage }
            key="TeamLoginPage"
          />
          <Route
            exact
            path="/signInFail"
            component={ SignInFail }
            key="signInFail"
          />
          ,
          <Route component={ NotFoundPage } key="notFoundPage" />
        </Switch>
      );
    }
    return baseRoutes;
  }, [status, user]);

  return (
    <ErrorBoundary>
      { isAccessAllow ? (
        <div
          ref={ appRef }
          className={ `app-wrapper app-wrapper-${layout.appColor} ` }
        >
          { renderMeta() }
          <div
            className={ `app ${layout.pageLessened ? "app__lessen" : "app__widen"}` }
          >
            { layout.isHeaderVisible && <HeaderContainer /> }

            <Suspense fallback={ <div /> }>{ routes }</Suspense>

            { layout.isFooterVisible && <Footer /> }

            <div className="global-controls">
              <AuthChildForbidden />

              <AuthModal />

              <InfoModal
                isOpened={ layout.showInfoModal }
                title={ layout.infoModalTitle }
                message={ layout.infoModalMessage }
                onClose={ () => dispatch(hideInfoModal()) }
                classNames={ layout.infoModalClassNames }
              >
                { layout.infoModalJSX }
              </InfoModal>

              <MaintenanceModal
                isOpened={ layout.showMaintenanceModal }
                onClose={ () => dispatch(hideMaintenanceModal()) }
              />

              { layout.showUserModal && (
                <UserModal
                  userId={ layout.selectedUser.id }
                  onClose={ () => dispatch(hideUserModal()) }
                />
              ) }

              { renderProjectQuestionnaireForm() }

              { renderAfterRegisterForm() }

              <EventsTrackParentIdContext.Provider value="FOOTER">
                <ScrollTopButton />
              </EventsTrackParentIdContext.Provider>
            </div>
          </div>
        </div>
      ) : (
        <div className="app__loader">
          <Loader />
        </div>
      ) }
    </ErrorBoundary>
  );
};
export default App;
